import React from "react";

import MirrorCard from "./MirrorCard";

import "./App.scss";

/* import Button_L from "./assets/button_l.svg"; */



const page_urls:string[] =
[
	"https://cerebro.link/manifesto/",
	"https://axiomavox.org",
	"https://cerebro.link/studio/",
];



interface Props_MirrorHall
{
	amount:number,
}

interface State_MirrorHall
{
	selected:number,
	force_hover:boolean,
}

class MirrorHall extends React.Component<Props_MirrorHall, State_MirrorHall>
{
	constructor(props:Props_MirrorHall, state:State_MirrorHall)
	{
		super(props, state);
		this.state =
		{
			selected: 1,
			force_hover: false
		}
	}

	componentDidMount()
	{
		document.addEventListener("keydown", this.handleKeyPress.bind(this));
	}
	componentWillUnmount()
	{
		document.removeEventListener("keydown", this.handleKeyPress.bind(this));
	}
	handleKeyPress(event:KeyboardEvent)
	{
		if (event.key === null)	return;
		else if (event.key === "ArrowLeft")		this.onClick_Button(true, null);
		else if (event.key === "ArrowRight")	this.onClick_Button(false, null);
		else if (event.key === "Enter")
		{
			window.location.href = page_urls[this.state.selected];
		}
	}

	onClick_Button(left:boolean, event:React.MouseEvent<HTMLDivElement,MouseEvent> | null)
	{
		let new_index = this.state.selected + (left ? -1 : 1);
		if (new_index < 0)
			new_index = 2;
		if (new_index > 2)
			new_index = 0;
		this.setState({ selected: new_index });
	}

	onClick_Card(clicked:number, url:string, event:React.MouseEvent<HTMLDivElement,MouseEvent> | null)
	{
		if (this.state.selected === clicked)
		{
			if (this.state.force_hover || !window.matchMedia("(hover: none)").matches)
				window.location.href = url;
		}
		else
		{
			this.setState({ selected: clicked });
		}
		this.setState({ force_hover: window.matchMedia("(hover: none)").matches });
	}

	render()
	{
		let classes_0:string = (this.state.selected === 0 ? "center" : (this.state.selected === 1 ? "left" : "right"));
		let classes_1:string = (this.state.selected === 1 ? "center" : (this.state.selected === 2 ? "left" : "right"));
		let classes_2:string = (this.state.selected === 2 ? "center" : (this.state.selected === 0 ? "left" : "right"));
		classes_0 += " " + (this.state.selected === 0 ? "front" : (this.state.selected === 1 ? "turn-l" : "turn-r"));
		classes_1 += " " + (this.state.selected === 1 ? "front" : (this.state.selected === 2 ? "turn-l" : "turn-r"));
		classes_2 += " " + (this.state.selected === 2 ? "front" : (this.state.selected === 0 ? "turn-l" : "turn-r"));
		if (this.state.force_hover)
		{
			classes_0 += (this.state.selected === 0 ? " hover" : " ");
			classes_1 += (this.state.selected === 1 ? " hover" : " ");
			classes_2 += (this.state.selected === 2 ? " hover" : " ");
		}
/*
		let nav_buttons:JSX.Element = (<div></div>);
		if (window.matchMedia("(hover: none)").matches)
		{
			nav_buttons = 
			(
				<div className="NavButtons">
					<div className="ToggleButton left" onClick={this.onClick_Button.bind(this, true)}>
						<img src={Button_L} alt="Button_L" />
					</div>
					<div className="ToggleButton right" onClick={this.onClick_Button.bind(this, false)}>
						<img src={Button_L} alt="Button_R" />
					</div>
				</div>
			);
		}
*/
		return (
			<div className="MirrorHallContainer">
				<div className="MirrorHall">
					<MirrorCard
						index={0}
						name={"Philosophy"}
						bgcolor="#3D0011"
						bgimage=	"url(./assets/cl-manifesto.png)"
						hover_image="url(./assets/cl-manifesto_hover.png)"
						force_hover={this.state.force_hover}
						classes={classes_0}
						selected={this.state.selected === 0}
						onClick={this.onClick_Card.bind(this, 0, page_urls[0])}>
					</MirrorCard>
					<MirrorCard
						index={1}
						name={"Laboratory"}
						bgcolor="#001741"
						bgimage=	"url(./assets/axiomavox.png)"
						hover_image="url(./assets/axiomavox_hover.png)"
						force_hover={this.state.force_hover}
						classes={classes_1}
						selected={this.state.selected === 1}
						onClick={this.onClick_Card.bind(this, 1, page_urls[1])}>
					</MirrorCard>
					<MirrorCard
						index={2}
						name={"Project Factory"}
						bgcolor="#886E50"
						bgimage=	"url(./assets/cerebrolink.png)"
						hover_image="url(./assets/cerebrolink_hover.png)"
						force_hover={this.state.force_hover}
						classes={classes_2}
						selected={this.state.selected === 2}
						onClick={this.onClick_Card.bind(this, 2, page_urls[2])}>
					</MirrorCard>
				</div>
			</div>
		);
	}
}

export default MirrorHall;