import React from "react";

import MirrorHall from "./MirrorHall";

import "./App.scss";

/*
import mobile from "./assets/mobile.svg"
			<div id="warning-message">
				<img src={mobile} alt="" />
				<br/>Please rotate your device.
			</div>
*/



const path_bgvideo = "./assets/bg.mp4";



const App: React.FC = () =>
{
	return (
		<div className="App">
            <div className="BackgroundVideo">
				<video muted loop autoPlay playsInline>
					<source type="video/mp4" src={path_bgvideo} />
					Your browser does not support the video tag.
				</video>
            </div>
			<div id="container">
				<header className="Header">
					<h1 className="text-gradient">Change your perspective</h1>
				</header>
				<MirrorHall amount={3} />
			</div>
		</div>
	);
}

export default App;