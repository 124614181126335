import React from "react";

import "./App.scss";



interface Props_MirrorCard
{
	name:string,
	index:number,
	classes:string,
	bgcolor:string,
	bgimage:string,
	hover_image:string,
	force_hover:boolean,
	selected:boolean,
	onClick:(event:React.MouseEvent<HTMLDivElement,MouseEvent>)=>void
}

interface State_MirrorCard
{
	hover:boolean,
}

class MirrorCard extends React.Component<Props_MirrorCard, State_MirrorCard>
{
	constructor(props:Props_MirrorCard, state:State_MirrorCard)
	{
		super(props, state);
		this.state =
		{
			hover: false,
		}
	}

	onMouseEnter(event:React.MouseEvent<HTMLDivElement,MouseEvent>)
	{
		this.setState({ hover: true });
	}

	onMouseLeave(event:React.MouseEvent<HTMLDivElement,MouseEvent>)
	{
		this.setState({ hover: false });
	}

	render()
	{
		const hovering:boolean = this.props.selected && (this.state.hover || this.props.force_hover);
		return (
			<div className={"MirrorCard " + this.props.classes}
				onClick={this.props.onClick}
				onMouseEnter={this.onMouseEnter.bind(this)}
				onMouseLeave={this.onMouseLeave.bind(this)}
				style={{
					backgroundColor: this.props.bgcolor,
					backgroundImage: this.props.bgimage
				}}>
				<div className="MirrorCardHighlight"
					style={{
						backgroundImage: this.props.hover_image,
						opacity:(hovering ? 1 : 0),
						zIndex: 0
					}}>
					<h3 className="text-gradient">Visit website</h3>
				</div>
				<h2 className="text-gradient" style={{ opacity: 1, zIndex: 10 }}>{this.props.name}</h2>
				<div className="Border" style={this.state.hover && !this.props.force_hover ? { borderColor: "#BBB" } : {}}></div>
			</div>
		);
	}
}

export default MirrorCard;